import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getEnabledElement, Enums } from '@cornerstonejs/core';
import { classes } from '@ohif/core';

const { studyMetadataManager } = classes;

async function fetchWindowingData(
  sopInstanceUID,
  studyInstanceUID,
  precision_filter_status = false,
  request_on_page_load = true
) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const client = params.get('client');
  console.log('clientclientclientclient', client);
  // const response = await fetch(`http://primendt.localhost:8000/api/precision-windowing`, {
  const response = await fetch(`https://${client}.orbixnde.com/api/precision-windowing`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sopInstanceUID,
      studyInstanceUID,
      precision_filter_status,
      request_on_page_load,
    }),
  });
  const data = await response.json();
  console.log('GHGH data', data);
  return data;
}

async function updateWindowingData(studyInstanceUID, sopInstanceUID, windowWidth, windowLevel) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const client = params.get('client');
  console.log('clientclientclientclient', client);
  console.log(
    'TLL Arif Updating Windowing Data:',
    studyInstanceUID,
    sopInstanceUID,
    windowWidth,
    windowLevel
  );
  // const response = await fetch(`http://primendt.localhost:8000/api/precision-windowing`, {
  const response = await fetch(`https://${client}.orbixnde.com/api/precision-windowing`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ studyInstanceUID, sopInstanceUID, windowWidth, windowLevel }),
  });
  const data = await response.json();
  console.log('TLL Update response data', data);
  return data;
}

function extractSOPInstanceUIDFromViewport(imageId) {
  const imageIdString = imageId[0];
  if (typeof imageIdString !== 'string') {
    throw new TypeError('imageId must be a string');
  }
  const url = imageIdString.replace('wadors:', '');
  const parts = url.split('/');
  const sopInstanceUIDIndex = parts.indexOf('instances') + 1;
  const sopInstanceUID = parts[sopInstanceUIDIndex];
  return sopInstanceUID;
}

const OrientationApplied = [];
const AutoPrecisionFilter = ({ precision_filter_status = false }) => {
  const isVOIUpdated = useRef(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const updateVOI = async eventDetail => {
      if (isVOIUpdated.current) {
        return;
      }
      const searchParams = new URLSearchParams(window.location.search);
      const studyInstanceUID = searchParams.get('StudyInstanceUIDs');

      if (!studyInstanceUID) {
        console.error('No StudyInstanceUID provided in the URL.');
        return;
      }

      const metadataProvider = classes.MetadataProvider;
      // without asymc eventDetails not available.
      if (precision_filter_status === true) {
        var viewportElement = document.querySelector('.cornerstone-viewport-element');
      } else {
        const viewportId = eventDetail.detail.viewportId;
        viewportElement = document.querySelector(`[data-viewport-uid="${viewportId}"]`);
      }

      const enabledElement = getEnabledElement(viewportElement);

      const imageIds = enabledElement.viewport.imageIds;

      if (imageIds.length > 0) {
        const imageId = imageIds[0];
        const dicomMetaData = metadataProvider.get('instance', imageId);
        const sopInstanceUID = dicomMetaData.SOPInstanceUID;

        const windowingData = await fetchWindowingData(
          sopInstanceUID,
          studyInstanceUID,
          precision_filter_status
        );
        const verificationResults = windowingData.verification_results;

        if (!verificationResults || verificationResults.length === 0) {
          console.log('No verification results found.');
          return;
        }

        const windowWidthResult =
          verificationResults.find(
            result => result.sop_instance_uid === sopInstanceUID && result.window_width
          ) || verificationResults.find(result => result.window_width);

        const windowLevelResult =
          verificationResults.find(
            result => result.sop_instance_uid === sopInstanceUID && result.window_level
          ) || verificationResults.find(result => result.window_level);

        if (!windowWidthResult || !windowLevelResult) {
          throw new Error('Window width or window level not found for the SOPInstanceUID.');
        }

        const window_width = Number(windowWidthResult.window_width);
        const window_level = Number(windowLevelResult.window_level);

        const lower_value = window_level - window_width / 2;
        const upper_value = window_level + window_width / 2;

        viewportElement.removeEventListener(Enums.Events.VOI_MODIFIED, updateVOI);

        enabledElement.viewport.setVOI({
          lower: lower_value,
          upper: upper_value,
        });

        const viewport = enabledElement.viewport;
        const sopInstanceUIDFromViewport = extractSOPInstanceUIDFromViewport(viewport.imageIds);

        if (!OrientationApplied.includes(sopInstanceUIDFromViewport)) {
          if (dicomMetaData.PatientOrientation) {
            adjustOrientation(dicomMetaData);
            if (!OrientationApplied.includes(sopInstanceUIDFromViewport)) {
              OrientationApplied.push(sopInstanceUIDFromViewport);
            }
          }
        }

        enabledElement.viewport.render();

        viewportElement.addEventListener(Enums.Events.VOI_MODIFIED, updateVOI);

        isVOIUpdated.current = true;
      }
    };

    const handleElement = async () => {
      const element = await waitForElement('.cornerstone-viewport-element');
      element.addEventListener(Enums.Events.VOI_MODIFIED, updateVOI);

      return () => {
        element.removeEventListener(Enums.Events.VOI_MODIFIED, updateVOI);
      };
    };

    const handleElementLive = async () => {
      const element = await waitForElement('.cornerstone-viewport-element');
      let isAPICalled = false;

      const updateVOILive = async event => {
        if (isAPICalled) {
          return;
        }
        const viewportElement = event.currentTarget;
        const enabledElement = getEnabledElement(viewportElement);

        if (!enabledElement) {
          console.error('Enabled element is undefined.');
          return;
        }

        const { lower, upper } = enabledElement.viewport.voiRange;
        const windowWidth = upper - lower;
        const windowLevel = lower + windowWidth / 2;

        const imageIds = enabledElement.viewport.imageIds;
        const imageId = imageIds[0];
        const metadataProvider = classes.MetadataProvider;
        const dicomMetaData = metadataProvider.get('instance', imageId);
        const sopInstanceUID = dicomMetaData?.SOPInstanceUID;

        if (!sopInstanceUID) {
          console.error('SOP Instance UID not found.');
          return;
        }

        const searchParams = new URLSearchParams(window.location.search);
        const studyInstanceUID = searchParams.get('StudyInstanceUIDs');

        if (studyInstanceUID) {
          await updateWindowingData(studyInstanceUID, sopInstanceUID, windowWidth, windowLevel);
          isAPICalled = true;
        }
      };

      const resetAPICallFlag = () => {
        isAPICalled = false;
      };

      element.addEventListener('mouseup', updateVOILive);
      element.addEventListener('mousedown', resetAPICallFlag);

      return () => {
        element.removeEventListener('mouseup', updateVOILive);
        element.removeEventListener('mousedown', resetAPICallFlag);
      };
    };

    handleElement();
    handleElementLive();
    if (precision_filter_status === true) {
      updateVOI();
    }
  }, [searchParams, precision_filter_status]);

  return null;
};

function adjustOrientation(dicomMetaData) {
  const rotationMap = {
    'L\\F': 'rotateViewportCCW',
    'R\\F': 'rotateViewportCW',
    'H\\F': 'rotateViewport180',
    'F\\F': 'flipViewportHorizontal',
  };

  const orientation = dicomMetaData.PatientOrientation.join('\\');
  const commandName = rotationMap[orientation];

  if (commandName) {
    commandsManager.runCommand(commandName);
    console.log(`Viewport rotated using command: ${commandName}`);
  } else {
    console.log('No rotation needed or orientation not mapped.');
  }
}

function waitForElement(selector) {
  return new Promise(resolve => {
    const element = document.querySelector(selector);
    if (element) {
      resolve(element);
      return;
    }

    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
          observer.disconnect();
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export default AutoPrecisionFilter;
